import item1 from './../svg/item1.svg'
import item2 from './../svg/item2.svg'
import item3 from './../svg/item3.svg'
import item4 from './../svg/item4.svg'
import item5 from './../svg/item5.svg'
import item6 from './../svg/item6.svg'
import item7 from './../svg/item7.svg'
import item8 from './../svg/item8.svg'
import item9 from './../svg/item9.svg'
import item10 from './../svg/item10.svg'
import item11 from './../svg/item11.svg'
import item12 from './../svg/item12.svg'
import item13 from './../svg/item13.svg'
import item14 from './../svg/item14.svg'

export type IName =
	| 'item1'
	| 'item2'
	| 'item3'
	| 'item4'
	| 'item5'
	| 'item6'
	| 'item7'
	| 'item8'
	| 'item9'
	| 'item10'
	| 'item11'
	| 'item12'
	| 'item13'
	| 'item14'

export const itemsPng = [
	{ path: item1, width: 120, height: 54 },
	{ path: item2, width: 165, height: 54 },
	{ path: item3, width: 143, height: 55 },
	{ path: item4, width: 186, height: 54 },
	{ path: item5, width: 190, height: 54 },
	{ path: item6, width: 181, height: 55 },
	{ path: item7, width: 167, height: 55 },
	{ path: item8, width: 182, height: 55 },
	{ path: item9, width: 140, height: 55 },
	{ path: item10, width: 211, height: 55 },
	{ path: item11, width: 119, height: 54 },
	{ path: item12, width: 161, height: 54 },
	{ path: item13, width: 135, height: 55 },
	{ path: item14, width: 119, height: 54 },
]
